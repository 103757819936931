import Axios from 'axios';
import router from '@/router';

import store from '../store/index';
import api from './api';

// Notification Component Add
/**
 * pobranie klucza autoryzujacego jesli jest
 * @returns {string|boolean}
 */
function auth() {
    if (localStorage.getItem('token')) {
        return `Bearer ${localStorage.getItem('token')}`;
    }
    return false;
}


/**
 * utworzenie instancji axiosa w stałej
 * @type {Axios}
 */
Axios.defaults.timeout = 1000000000;
const HTTP = Axios.create({
    baseURL: api.BASE_URL,
    crossDomain: true,
    timeout: 1000000000,
    transformRequest: [(data, headers) => {
        // eslint-disable-next-line no-param-reassign
        headers.Authorization = auth();
        if (data instanceof FormData) {
            return data;
        }
        return JSON.stringify(data);
    }],
    headers: {'Content-Type': 'application/json'},
});
/**
 * Globalny interceptor do obsługi różnych wyjątków
 */


HTTP.interceptors.response.use(response => response, (error) => {
    store.state.loading = false;
    // całkowity brak odpowiedzi w żądanym timeout, zwracamy promise i nic sie nie dzieje dalej
    if (typeof error.response === 'undefined') {
        alert('Problem z połączeniem internetowym! Spróbuj odświeżyć stronę');
        return Promise.reject(error);
    }
    // blad 401 - problem z kluczem, brak autoryzacji
    if (error.response.status === 401 && localStorage.getItem('token') != null) {
        localStorage.clear(); // Dla pewnosci lepiej wszystko usunąć
        router.push('/');
    }

    // proba zlapania ogolnych errorow
    if (error.response.status) {
        if (typeof error.response.data.violations !== 'undefined') {
            //  loopMessages(error.response.data.violations);
        }
    }
    return Promise.reject(error);
});


/**
 * eksport zmiennych:
 * HTTP/axios - AXIOS
 * api
 */
export default {
    api,
    axios: HTTP,
};
