let mutations = {
    loginSuccess(state, token) {
        state.token = token;
    },
    logout(state) {
        state.token = null;
        localStorage.removeItem('token')
    },
}
export default mutations
