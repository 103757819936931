import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store/index";

Vue.use(VueRouter);

const routes = [
    {
        path: "/login",
        name: 'Login',
        meta: {requiresAuth: false, title: 'Logowanie'},
        component: () => import("./Login"),
    },
    {
        path: "/settings",
        meta: {requiresAuth: true, title: 'Ustawienia'},

        component: () => import("./Settings"),
    },
    {
        path: "/",
        name: 'Home',
        meta: {requiresAuth: false, title: 'Strona główna'},
        component: () => import( "./Home"),
    },


];

const router = new VueRouter({
    routes,
    mode: "history",
});
router.beforeEach((to, from, next) => {
    const currentUser = store.state.token;
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);


    console.log(requiresAuth);
    if (requiresAuth && !currentUser) {
        next({path: "/login"});
    }
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    }
    next();
});
// router.beforeEach(AuthGuard);
export default router;
