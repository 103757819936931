/**
 * Domyślne akcje URL do określonych czynności, rejestracja, logowanie itp.
 * @string akcja
 */
const ACTION = {
    LOGIN: 'auth/login',
    DEVICE: 'auth/device',
    CHANGE_PASSWORD: 'auth/change-password',
    PLATFORMS: 'platforms',
};


/**
 * dopisanie URL jako prefix
 */

Object.keys(ACTION).forEach((key) => {
    ACTION[key] = process.env.VUE_APP_API_URL + ACTION[key];
});


/**
 * eksport zmiennych
 */
export default {
    URL: process.env.VUE_APP_API_URL,
    ACTION,
};
