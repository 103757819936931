<template>
  <div id="app">
    <notifications group="default"/>
    <div>
      <b-navbar fixed="top" toggleable="lg" type="dark" variant="dark">
        <b-navbar-brand :to="`/`">Licznik</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item :to="`/settings`" v-if="isLogged">Zalogowany</b-nav-item>
            <b-nav-item :to="`/login`" v-else>Zaloguj się</b-nav-item>
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item-dropdown text="Language/język" right>
              <b-dropdown-item href="#">PL</b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item-dropdown right v-if="isLogged">
              <!-- Using 'button-content' slot -->
              <template #button-content>
                <em>Konto</em>
              </template>
              <template v-if="isLogged">
                <b-dropdown-item href="#">Ustawienia platform</b-dropdown-item>
                <b-dropdown-item href="#" @click="logout">Wyloguj</b-dropdown-item>
              </template>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  computed: {
    isLogged() {
      return this.$store.state.token
    }
  },
  methods: {
    logout() {
      this.$store.commit('logout');
      this.$router.push("/login");

      this.$notify({
        group: 'default',
        title: 'Wylogowano z systemu',
        type: 'error',
        text: 'Wylogowano pomyślnie'
      });
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.vue-notification-wrapper {
  display: block;
  overflow: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
}

.vue-notification-group {
  display: block;
  position: fixed;
  z-index: 5000;
  margin-top:60px;
}
.vue-notification, .vue-notification-template {
  display: block;
  box-sizing: border-box;
  text-align: left;
}
</style>
