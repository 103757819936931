import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import Vuex from 'vuex'
import router from "@/router";
import store from "./store/index";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import http from "@/utils/http";
import Notifications from 'vue-notification'

Vue.use(Vuex)
Vue.use(BootstrapVue);
Vue.use(Notifications);

Vue.prototype.$http = http.axios;
Vue.prototype.$api = http.api.ACTION;

new Vue({
    router,
    render: h => h(App),
    store
}).$mount('#app')
